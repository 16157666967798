// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-archived-q-5769-yjli-3-zxkl-96-js": () => import("./../../../src/pages/about-us-archived-q5769yjli3zxkl96.js" /* webpackChunkName: "component---src-pages-about-us-archived-q-5769-yjli-3-zxkl-96-js" */),
  "component---src-pages-faqs-archived-q-5769-yjli-3-zxkl-96-js": () => import("./../../../src/pages/faqs-archived-q5769yjli3zxkl96.js" /* webpackChunkName: "component---src-pages-faqs-archived-q-5769-yjli-3-zxkl-96-js" */),
  "component---src-pages-faqs-mobile-js": () => import("./../../../src/pages/faqs-mobile.js" /* webpackChunkName: "component---src-pages-faqs-mobile-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nft-68-xdx-4-gkr-570-c-8-pc-40-rn-js": () => import("./../../../src/pages/nft-68xdx4gkr570c8pc40rn.js" /* webpackChunkName: "component---src-pages-nft-68-xdx-4-gkr-570-c-8-pc-40-rn-js" */),
  "component---src-pages-privacy-policies-js": () => import("./../../../src/pages/privacy-policies.js" /* webpackChunkName: "component---src-pages-privacy-policies-js" */),
  "component---src-pages-privacy-policies-mobile-js": () => import("./../../../src/pages/privacy-policies-mobile.js" /* webpackChunkName: "component---src-pages-privacy-policies-mobile-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-and-conditions-mobile-js": () => import("./../../../src/pages/terms-and-conditions-mobile.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-mobile-js" */)
}

